<template>
  <div class="attshiftcont">
    <div style="display: flex">
      <Headbuttom></Headbuttom>
      <Tabheader
        @handRegister="handRegister"
        @handFromshow="handFromshow"
        :titlename="titlename"
      ></Tabheader>
    </div>

    <div class="attheadfrom" v-show="flag">
      <el-form
        :inline="true"
        size="small"
        :model="searchParams"
        class="demo-form-inline"
      >
        <!-- <el-form-item label="用户名:">
                    <el-input v-model="searchParams.user" placeholder="用户名"></el-input>
                </el-form-item> -->
        <el-form-item :label="this.$t('devtable.xm')">
          <el-input
            v-model="searchParams.name"
            :placeholder="$t('devtable.xm')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('devtable.sjhm')">
          <el-input
            v-model="searchParams.mobile"
            :placeholder="$t('devtable.sjhm')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="开始时间">
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <Userdialog
      @userhandleClose="userhandleClose"
      :userdialog="userdialog"
      @userdialogVisibletrue="userdialogVisibletrue"
    >
      <el-form :model="delform">
        <el-form-item :label="$t('devtable.yhm')" :label-width="formLabelWidth">
          <el-input
            v-model="delform.user_name_input"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('devtable.mm')" :label-width="formLabelWidth">
          <el-input
            v-model="delform.user_pass_input"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
    </Userdialog>
    <Tables
      @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
      :configs="configs"
      :operation="operation"
    ></Tables>
		<!-- 修改 -->
    <Attshitdilg
      @addManagerSuccess="addManagerSuccess"
      ref="attshitdilg"
    ></Attshitdilg>
		<!-- 添加 -->
    <Adttshitdilg
      @addManagerSuccess="addManagerSuccess"
      ref="adttshitdilg"
    ></Adttshitdilg>
  </div>
</template>
<script>
import Tables from "@/components/table";
import Tabheader from "@/views/workattendance/components/tabheader";
import Attshitdilg from "./components/index";
import Adttshitdilg from "./components/addindex";
import Userdialog from "@/components/dialog/userfrom";

import methodlist from "../../utils/methods.js";
export default {
  components: {
    Tables,
    Tabheader,
    Attshitdilg,
    Userdialog,
    Adttshitdilg,
  },
  data() {
    return {
      flag: false,
      formLabelWidth: "86px",
      value1: "",
      searchParams: {
        name: "",
        mobile: "",
      },
      titlename: {
        name: this.$t("navLeft.addU"),
        placeholdername: "请点击查询",
      },
      userdialog: {
        dialogVisible: false,
        title: this.$t("devtable.scgly"),
      },
      delform: {
        user_name_input: "",
        user_pass_input: "",
      },
      delData: "",

      tableData: [],
      flags: {
        show: false, // 多选是否显示
        ishow: false, // 序号是否显示
      },
      // 分页数据
      configs: {
          page_index:1,
        pagesize: 10,
        total: 200,
        loading: false, // 加载状态
      },

      UserInfo: "",
    };
  },
  computed: {
    operation: function () {
      return {
        width: "200px",
        data: [
          {
            name: this.$t("tablename.bjH"),
            type: "primary",
          },
          {
            name: this.$t("tablename.sc"),
            type: "danger",
          },
        ],
      };
    },
    tableLabel: function () {
      return [
        {
          prop: "id",
          label: this.$t("devtable.glyid"),
          align: "center",
			width:120
        },
        {
          prop: "name",
          label: this.$t("devtable.yhm"),
          align: "center",
        },
        {
          prop: "password",
          label: this.$t("devtable.mm"),
          align: "center",
        },
        {
          prop: "mobile",
          label: this.$t("devtable.sjhm"),
          align: "center",
        },
        {
          prop: "type_hint",
          label: this.$t("devtable.jsmc"),
          width: 140,
          align: "center",
        },
        {
          prop: "menu_hint",
          label: this.$t("devtable.ymqx"),
          width: 450,
          align: "center",
        },
      ];
    },
  },
  mounted() {
    this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
    this.getAdminstrorList(1);
  },
  methods: {
    handlerow(v, name) {
      console.log("handlerow", v);
      // return false
      if (name == "删除" || name == "Delete") {
        if (v.is_sys_admin == "0") {
          this.delData = v;
          this.userdialog.dialogVisible = true;
        }
        if (v.is_sys_admin == "1") {
          this.$message({
            type: "warning",
            message: "该管理员没权限删除!",
          });
        }
      } else if (name == "编辑" || name == "Edit") {
        if (this.UserInfo.is_sys_admin == "1") {
          this.$refs.attshitdilg.getEditManagerInfo(v);
          this.$refs.attshitdilg.dialogFormVisible = true;
        } else {
          if (
            this.UserInfo.is_sys_admin == "0" &&
            v.name == this.UserInfo.user_name_save
          ) {
            this.$refs.attshitdilg.getEditManagerInfo(v);
            this.$refs.attshitdilg.dialogFormVisible = true;
          } else {
            this.$message({
              message: "该管理员没权限修改!",
              type: "warning",
            });
          }
        }
      }
    },
    //关闭删除框
    userhandleClose() {
      console.log("userhandleClose");
      this.userdialog.dialogVisible = false;
    },

    //删除弹窗: 确定删除管理员
    userdialogVisibletrue() {
      console.log("userdialogVisibletrue");

      if (this.delform.user_name_input != this.UserInfo.user_name_save) {
        this.$message({
          type: "warning",
          message: this.$t("devtable.yhmsryw"),
        });
        return false;
      }

      if (this.delform.user_pass_input != this.UserInfo.user_pass_save) {
        this.$message({
          type: "warning",
          message: this.$t("devtable.yhmmsryw"),
        });
        return false;
      }
      const params = {
        method: methodlist.ee_manager_del,
        name: this.delform.user_name_input,
        password: this.$md5(this.delform.user_pass_input),
        id: this.delData.id,
      };
      this.$serve(params).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message({
            message: this.$t("devtable.sccg"),
            type: "success",
          });
          this.userdialog.dialogVisible = false;
          this.getAdminstrorList(1);
        } else {
          //删除失败
          if (res.data.code == -3) {
            this.$message({
              message: this.$t("devtable.scsbglybcz"),
              type: "warning",
            });
          } else if (res.data.code == -8) {
            this.$message({
              message: this.$t("devtable.scsbmyyhqxhzhcw"),
              type: "warning",
            });
          } else {
            this.$message({
              message: this.$t("devtable.scsb"),
              type: "warning",
            });
          }
        }
      });
    },
    onSubmit() {
      console.log("submit!");
      this.getAdminstrorList(1);
    },
    handRegister() {
      if (this.UserInfo.is_sys_admin == "0") {
        this.$message({
          message: this.$t("devtable.gglymqxtj"),
          type: "warning",
        });
        return;
      }

      this.$refs.adttshitdilg.dialogFormVisible = true;
    },
    handFromshow() {
      this.flag = !this.flag;
    },
    handlpages(v) {
      this.configs.page_index = v;
      this.getAdminstrorList();
    },
    handleSizeChange(v) {
      this.configs.page_index = 1;
      this.configs.pagesize = v;
      this.getAdminstrorList();
    },

    getAdminstrorList(pageIndex) {
      const params = {
        method: methodlist.ee_manager_list,
        agent_id: this.UserInfo.agent_id,
        manager_id: this.UserInfo.manager_id,
        page_index: pageIndex?pageIndex:this.configs.page_index,
        page_size: this.configs.pagesize,
        name: this.searchParams.name,
        mobile: this.searchParams.mobile,
      };
      this.$serve(params).then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        this.configs.total = res.data.count;
        this.tableData.forEach((el) => {
          if (el.type == 1) {
            el.type_hint = {
              name: this.$t("devtable.rsjl"),
              state: true,
              type: "primary",
            };
          } else {
            el.type_hint = {
              name: this.$t("devtable.czy"),
              state: true,
              type: "primary",
            };
          }

          el.password = "******";

          el.menu_hint = {
            name: this.getMenuShowHint(el.menu),
            state: true,
            type: "primary",
          };
        });
      });
    },
    addManagerSuccess() {
      this.getAdminstrorList(1);
    },
    getMenuShowHint(menu) {
      let menu_01 = ["1", "2"];
      let menu_02 = ["3", "4"];
      let menu_03 = ["5", "6", "7", "8"];
      let menu_04 = ["9", "10"];
      let menu_05 = ["11", "12", "13", "14", "15", "16", "17", "18"];
      let menu_06 = ["19", "20", "21", "22", "23", "24"];
      let menu_07 = ["25", "26"];
      let menuStr = [];
      let flag = 0;

      flag = 0;
      menu_01.forEach((item) => {
        if (menu.indexOf(item) != -1) {
          flag = 1;
        }
      });
      if (flag == 1) {
        menuStr.push(this.$t("navLeft.qygl"));
      }

      flag = 0;
      menu_02.forEach((item) => {
        if (menu.indexOf(item) != -1) {
          flag = 1;
        }
      });
      if (flag == 1) {
        menuStr.push(this.$t("navLeft.sbgl"));
      }

      flag = 0;
      menu_03.forEach((item) => {
        if (menu.indexOf(item) != -1) {
          flag = 1;
        }
      });
      if (flag == 1) {
        menuStr.push(this.$t("navLeft.ygglH"));
      }

      flag = 0;
      menu_04.forEach((item) => {
        if (menu.indexOf(item) != -1) {
          flag = 1;
        }
      });
      if (flag == 1) {
        menuStr.push(this.$t("navLeft.fkgl"));
      }

      flag = 0;
      menu_05.forEach((item) => {
        if (menu.indexOf(item) != -1) {
          flag = 1;
        }
      });
      if (flag == 1) {
        menuStr.push(this.$t("navLeft.kqgl"));
      }

      flag = 0;
      menu_06.forEach((item) => {
        if (menu.indexOf(item) != -1) {
          flag = 1;
        }
      });
      if (flag == 1) {
        menuStr.push(this.$t("navLeft.bbsj"));
      }

      flag = 0;
      menu_07.forEach((item) => {
        if (menu.indexOf(item) != -1) {
          flag = 1;
        }
      });
      if (flag == 1) {
        menuStr.push(this.$t("navLeft.xtsz"));
      }

      return menuStr.join(",");
    },
  },
};
</script>
<style lang="less" scoped>
.attshiftcont {
  padding: 20px;
  background-color: #fff;
  .attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
}
</style>