<template>
    <div class="mauntacont">
        <el-dialog :title="$t('devtable.tjjsgly')" center append-to-body width="54%" label-width="110px" :visible.sync="dialogFormVisible">
           <el-form ref="form" :model="form" label-width="110px">
               <el-row >
                   <el-col :span="10">
                       <el-form-item :label="$t('devtable.yhm')">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                   </el-col>
                   <el-col :span="10" :offset="3">
                       <el-form-item :label="$t('devtable.yhmm')">
                            <el-input v-model="form.password"></el-input>
                        </el-form-item>
                   </el-col>
               </el-row>

               <el-row >
                   <el-col :span="10">
                       <el-form-item :label="$t('devtable.sjhm')">
                            <el-input v-model="form.mobile"></el-input>
                        </el-form-item>
                   </el-col>
                   <el-col :span="10" :offset="3">
                        <el-form-item :label="$t('devtable.yhjs')" class="two">
                            <el-select v-model="form.type" :placeholder="$t('devtable.qxz')">
                            <el-option :label="$t('devtable.rsjl')" value="1"></el-option>
                            <el-option :label="$t('devtable.czy')" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                   </el-col>
               </el-row>

               <el-row >
                   <el-col :span="10">
                       <el-form-item :label="$t('devtable.bmqx')" label-width="200px" :placeholder="$t('devtable.qxz')">
                            <!-- <el-select v-model="form.dept_ids" :placeholder="$t('devtable.qxz')" multiple collapse-tags>
                            <el-option :value="mineStatus" style="height: auto">
                                <el-tree :data="treeList" default-expand-all show-checkbox check-strictly node-key="id"
                                    ref="tree" highlight-current :props="defaultProps"
                                    @check-change="handleCheckChange"></el-tree>
                                </el-option>
                            </el-select>       
                                            -->
                            <Select @handleCheckChange="handleCheckChange" :id="id"></Select>
                        </el-form-item>
                   </el-col>
                   <!-- <el-col :span="10" :offset="3">
                       <el-form-item label="活动名称">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                   </el-col> -->
               </el-row>

               <div style="line-height: 40px; text-align: center; border-bottom: 1px solid #cccccc; ">
                   <p>{{$t('devtable.tjymqx')}}</p>
               </div>

               <el-table
                :data="tableData"
                border
                style="width: 110%;margin-top:20px">

                <el-table-column
                    prop="data_a"
                    :label="$t('navLeft.qygl')"
                    width="110">
                    <template slot-scope="scope">
                        <!-- {{scope.row.date}} -->
                        <el-checkbox v-if="scope.row.data_a" v-model="scope.row.data_a.checked">{{scope.row.data_a.name}}</el-checkbox>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="data_b"
                    :label="$t('devtable.sb')"
                    width="110">
                    <template slot-scope="scope">
                        <!-- {{scope.row.date}} -->
                        <el-checkbox v-if="scope.row.data_b" v-model="scope.row.data_b.checked">{{scope.row.data_b.name}}</el-checkbox>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="data_c"
                    :label="$t('navLeft.ygglH')"
                    width="110">
                    <template slot-scope="scope">
                        <!-- {{scope.row.date}} -->
                        <el-checkbox v-if="scope.row.data_c" v-model="scope.row.data_c.checked">{{scope.row.data_c.name}}</el-checkbox>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="data_d"
                    :label="$t('navLeft.fkgl')"
                    width="110">
                    <template slot-scope="scope">
                        <!-- {{scope.row.date}} -->
                        <el-checkbox v-if="scope.row.data_d" v-model="scope.row.data_d.checked">{{scope.row.data_d.name}}</el-checkbox>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="data_e"
                    :label="$t('navLeft.kqgl')"
                    width="110">
                    <template slot-scope="scope">
                        <!-- {{scope.row.date}} -->
                        <el-checkbox v-if="scope.row.data_e" v-model="scope.row.data_e.checked">{{scope.row.data_e.name}}</el-checkbox>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="data_f"
                    :label="$t('navLeft.bbsj')"
                    width="110">
                    <template slot-scope="scope">
                        <!-- {{scope.row.date}} -->
                        <el-checkbox v-if="scope.row.data_f" v-model="scope.row.data_f.checked">{{scope.row.data_f.name}}</el-checkbox>
                    </template>
                </el-table-column>

                <!-- s -->
                <!-- <el-table-column
                    prop="data_f"
                    :label="this.$t('LaterOhther.znIPC')"
                    width="110">
                    <template slot-scope="scope">
                        {{scope.row.date}}
                        <el-checkbox v-if="scope.row.data_zn" v-model="scope.row.data_zn.checked">{{scope.row.data_zn.name}}</el-checkbox>
                    </template>
                </el-table-column> -->


                <el-table-column
                    prop="data_g"
                    :label="$t('navLeft.xtsz')"
                    width="110">
                    <template slot-scope="scope">
                        <!-- {{scope.row.date}} -->
                        <el-checkbox v-if="scope.row.data_g" v-model="scope.row.data_g.checked" :disabled="scope.row.data_g.name =='管理员设置' ? true:false" >{{scope.row.data_g.name}}</el-checkbox>
                    </template>
                </el-table-column>
                
            </el-table>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveResult">{{$t('devtable.bcz')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Select from '@/components/selectree/wltreeselect'
import methodlist from '../../../utils/methods'

export default {
    components:{
        Select
    },    
    data () {
        return {
            id:'',
            opTypeFlag: 0,
            form: {
                name: '',
                password: '',
                mobile: '',
                type: '1',
                dept_id: '',
                menu: ''
            },
            editId: '',
            dialogFormVisible: false,
            tableData: [
                {
                    data_a:{
                        name:this.$t('navLeft.qyzl'),
                        checked:'',
                        menu_id: '1'
                    },
                    data_b: {
                        name:this.$t('navLeft.sbgl'),
                        checked:'',
                        menu_id: '3'
                    },
                    data_c: {
                        name:this.$t('navLeft.ryzl'),
                        checked:'',
                        menu_id: '5'
                    },
                    data_d: {
                        name:this.$t('navLeft.fkdj'),
                        checked:'',
                        menu_id: '9'
                    },
                    data_e: {
                        name:this.$t('navLeft.sjly'),
                        checked:'',
                        menu_id: '11'
                    },
                    data_f: {
                        name:this.$t('navLeft.txjl'),
                        checked:'',
                        menu_id: '19'
                    },
                    data_zn: {
                        name:this.$t('LaterOhther.bjjlcl'),
                        checked:'',
                        menu_id: '27'
                    },
                    data_g: {
                        name:this.$t('navLeft.glysz'),
                        checked:'',
                        menu_id: '25'
                    }                                                            
                },
                {
                    data_a:{
                        name:this.$t('navLeft.qyjg'),
                        checked:'',
                        menu_id: '2'
                    },
                    data_b: {
                        name:this.$t('navLeft.ztcx'),
                        checked:'',
                        menu_id: '4'
                    },
                    data_c: {
                        name:this.$t('navLeft.zzsh'),
                        checked:'',
                        menu_id: '6'
                    },
                    data_d: {
                        name:this.$t('navLeft.yysh'),
                        checked:'',
                        menu_id: '10'
                    },
                    data_e: {
                        name:this.$t('navLeft.kqcs'),
                        checked:'',
                        menu_id: '12'
                    },
                    data_f: {
                        name:this.$t('navLeft.kqrb'),
                        checked:'',
                        menu_id: '20'
                    },
                    data_zn: {
                        name:this.$t('LaterOhther.bjcljl'),
                        checked:'',
                        menu_id: '28'
                    },
                    data_g: {
                        name:this.$t('navLeft.llksz'),
                        checked:'',
                        menu_id: '26'
                    }                                                            
                },
                {                                    
                    data_c: {
                        name:this.$t('navLeft.qxgl'),
                        checked:'',
                        menu_id: '7'
                    },                 
                    data_e: {
                        name:this.$t('navLeft.kqbc'),
                        checked:'',
                        menu_id: '13'
                    },
                    data_f: {
                        name:this.$t('navLeft.kqyb'),
                        checked:'',
                        menu_id: '21'
                    } ,
                    data_zn: {
                        name:this.$t('LaterOhther.szbjts'),
                        checked:'',
                        menu_id: '29'
                    }, 
                                                                                             
                },
                {
                    data_c: {
                        name:this.$t('navLeft.hkbk'),
                        checked:'',
                        menu_id: '8'
                    },                 
                    data_e: {
                        name:this.$t('navLeft.kqpb'),
                        checked:'',
                        menu_id: '14'
                    },
                    data_f: {
                        name:this.$t('navLeft.fkjl'),
                        checked:'',
                        menu_id: '22'
                    } ,
                    data_zn: {
                        name:this.$t('LaterOhther.tsbjjl'),
                        checked:'',
                        menu_id: '30'
                    },                     
                },
                {
                    data_e: {
                        name:this.$t('navLeft.jrgl'),
                        checked:'',
                        menu_id: '15'
                    },
                    data_f: {
                        name:this.$t('navLeft.ffjl'),
                        checked:'',
                        menu_id: '23'
                    } ,
                    data_zn: {
                        name:this.$t('LaterOhther.ssspck'),
                        checked:'',
                        menu_id: '31'
                    },                      
                },
                {
                    data_e: {
                        name:this.$t('navLeft.qjgl'),
                        checked:'',
                        menu_id: '16'
                    },
                    data_f: {
                        name:this.$t('navLeft.zpjl'),
                        checked:'',
                        menu_id: '24'
                    },
                    data_zn: {
                        name:this.$t('LaterOhther.spckjl'),
                        checked:'',
                        menu_id: '32'
                    },                      
                },
                {
                    data_e: {
                        name:this.$t('navLeft.qdgl'),
                        checked:'',
                        menu_id: '17'
                    }
                } ,
                {
                    data_e: {
                        name:this.$t('navLeft.kqcl'),
                        checked:'',
                        menu_id: '18'
                    }
                } 

            ]
        }
    },
    mounted () {
       this.UserInfo = JSON.parse(window.localStorage.getItem('user')); 
    },
    methods: {
        // 获取企业部门id
        handleCheckChange(val){
            let dept_id=[]
			console.log(val);
            val.forEach(el=>{
                dept_id.push(el.id)
            })
            this.form.dept_id = dept_id.join(",");
            console.log(`677`,this.form.dept_id)
        },
        saveResult(){
            this.form.menu = '';
            this.tableData.forEach(item=>{
                if(item.data_a){
                    if(item.data_a.checked == true)
                        this.form.menu += this.form.menu.length == 0 ? item.data_a.menu_id : (','+item.data_a.menu_id)
                }

                if(item.data_b){
                    if(item.data_b.checked == true)
                        this.form.menu += this.form.menu.length == 0 ? item.data_b.menu_id : (','+item.data_b.menu_id)
                }
                if(item.data_c){
                    if(item.data_c.checked == true)
                        this.form.menu += this.form.menu.length == 0 ? item.data_c.menu_id : (','+item.data_c.menu_id)
                }
                if(item.data_d){
                    if(item.data_d.checked == true)
                        this.form.menu += this.form.menu.length == 0 ? item.data_d.menu_id : (','+item.data_d.menu_id)
                }
                if(item.data_e){
                    if(item.data_e.checked == true)
                        this.form.menu += this.form.menu.length == 0 ? item.data_e.menu_id : (','+item.data_e.menu_id)
                }
                if(item.data_f){
                    if(item.data_f.checked == true)
                        this.form.menu += this.form.menu.length == 0 ? item.data_f.menu_id : (','+item.data_f.menu_id)
                }
                if(item.data_g){
                    if(item.data_g.checked == true)
                        this.form.menu += this.form.menu.length == 0 ? item.data_g.menu_id : (','+item.data_g.menu_id)
                }                                                                                
            })
            console.log(this.form.menu)            
            if(this.opTypeFlag == 0)
                this.addNewManager();
            else
                this.editManager();
        },
        addNewManager(){
            const params = {
                method: methodlist.ee_manager_add,
                agent_id: this.UserInfo.agent_id,             
                name: this.form.name,
                password: this.$md5(this.form.password),
                mobile: this.form.mobile,
                type: this.form.type,
                menu: this.form.menu,
                dept_id: this.form.dept_id
            }

            this.$serve(params).then(res => {
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        message: this.$t('devtable.tjcg'),
                        type: 'success'
                    })
                    this.dialogFormVisible = false;
                    this.$emit("addManagerSuccess");
                }else{
                    if(res.data.code == -4){
                        this.$message({
                            message: this.$t('devtable.tjsbxmycz'),
                            type: 'warning'
                        }) 
                    }else{
                        this.$message({
                            message: this.$t('devtable.tjsb'),
                            type: 'warning'
                        }) 
                    }
                }
            })
        },
        editManager(){
            //console.log(this.form.password,this.$md5(this.form.password))          
            const params = {
                method: methodlist.ee_manager_update,
                id: this.editId,          
                name: this.form.name,
                password: (this.form.password.length == 0) ? '' : this.$md5(this.form.password),
                mobile: this.form.mobile,
                type: this.form.type,
                menu: this.form.menu,
                dept_id: this.form.dept_id
            }

            this.$serve(params).then(res => {
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        message: this.$t('devtable.xgcg'),
                        type: 'success'
                    })
                    this.dialogFormVisible = false;
                    this.$emit("addManagerSuccess");
                }else{
                    if(res.data.code == -4){
                        this.$message({
                            message: this.$t('devtable.xgsbxmycz'),
                            type: 'warning'
                        }) 
                    }else{
                        this.$message({
                            message: this.$t('devtable.xgsb'),
                            type: 'warning'
                        }) 
                    }
                }
            })
        },
        getEditManagerInfo(managerInfo){
            this.id = managerInfo.id
            this.opTypeFlag = 1;
            this.editId = managerInfo.id;
            const params = {
                method: methodlist.ee_manager_get,
                id: managerInfo.id
            }
            this.$serve(params).then(res => {
                console.log(res);
                this.form.name = res.data.data.name;
                this.form.mobile =  res.data.data.mobile;
                this.form.type = res.data.data.type;
                this.form.dept_id = res.data.data.dept_id;
                let arr=res.data.data.menu.split(",");
                this.tableData.forEach(item=>{

                    if(item.data_a){
                        if(arr.indexOf(item.data_a.menu_id) != -1)
                            item.data_a.checked = true
                    }
                    if(item.data_b){
                        if(arr.indexOf(item.data_b.menu_id) != -1)
                            item.data_b.checked = true
                    }
                    if(item.data_c){
                        if(arr.indexOf(item.data_c.menu_id) != -1)
                            item.data_c.checked = true
                    }
                    if(item.data_d){
                        if(arr.indexOf(item.data_d.menu_id) != -1)
                            item.data_d.checked = true
                    }
                    if(item.data_e){
                        if(arr.indexOf(item.data_e.menu_id) != -1)
                            item.data_e.checked = true
                    }
                    if(item.data_f){
                        if(arr.indexOf(item.data_f.menu_id) != -1)
                            item.data_f.checked = true
                    }
                    if(item.data_zn){
                        if(arr.indexOf(item.data_zn.menu_id) != -1)
                            item.data_zn.checked = true
                    }
                    if(item.data_g){
                        if(arr.indexOf(item.data_g.menu_id) != -1)
                            item.data_g.checked = true
                    }
                })
            })
        }
    }
}
</script>
<style lang="less" scoped>
.s{
   
}
</style>