<template>
    <div>
        <el-dialog
        :title="userdialog.title"
        :visible.sync="userdialog.dialogVisible"
        width="20%"
        append-to-body
        :before-close="userhandleClose">
        <slot></slot>
        <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogVisiblefalse">取 消</el-button> -->
            <el-button type="primary" @click="userdialogVisibletrue">{{$t('tablename.qd')}}</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data (){
        return{

        }
    },
    props:{
        userdialog:Object,
    },
    methods:{
        userdialogVisibletrue(){
            this.$emit('userdialogVisibletrue')
        },
        userhandleClose(){
            this.$emit('userhandleClose')
        }
    }
}
</script>